.greenlin li{
    border-bottom: 2px solid #0D731D;
    padding-bottom:  0.5rem;
    padding-top: 0.5rem;
}

.greenline2{
    text-align: center;
    margin-top: 5px;
    margin-bottom: 30px;
    border-bottom: 2px solid #0D731D;
}