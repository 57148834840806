
.Stopic{
    border-top: 1.5px solid black;
}
.ada1 li {
    list-style-type: disc;
    font-size: 16px;
    font-weight: 600;
  }
.ada2 li {
    list-style-type: disc;
    font-size: 16px;
    font-weight: 600;
  }
  .pad1{
      padding-bottom: 3rem;
      
  }
  .pad2{
      padding-bottom: 3rem;
  }
  .Stopic1{
    border-top: 1.5px solid black;
}