.main__head3{
    display: flex;
    /* justify-content: center;
    align-items: center; */
}.sub__head3{
    /* padding: 0rem 0rem;
    margin: 0 0rem; */
    /* position:absolute; */
    /* top: 1rem; */
    width: 100%;
    height: 700px;
    /* border: 10px solid green; */
}
#myTab{
    background-color: #FFD500;
    padding-left: 5%;
}

.mr-2{
    /* width: 16%; */
    width: 19%;
    
    
}

button{
    width: 60%;
}

/* [role="tab"].active { */
    /* background-color: #0E7FFC; */
    /* background-color: white; */
/* } */

/* [role="tab"].active, [role="tab"].active:hover {
    color: white;
    background-color: green;
    border-color: #1C64F2;
} */

[role="tab"].active {
    background-color: var(--blue);
}

.bureaus_heading{
    text-align: center;
    
}

.bureaus_heading h2{
    text-decoration: underline;
    font-size: 45px;
    font-weight: 600;
    font-family: 'Playfair Display';
    /* margin: 10px; */
    padding: 18px;
}
.b__image{
    width: 100vw;
}