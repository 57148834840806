.brbox{
    background-color: #1C3A5B;
}
/* .img_box{
    padding: 0 10%;
    margin-bottom: 80px;
}

.brImgs{
    margin: -8%;
    z-index: 3000;
}

.br_img_box{
    display: flex;
    color: #ffffff;
    text-align: center;
    font-family: 'Playfair Display';
}

.contentbox{
    background-color: #1C3A5B;
}

.contentHdng{
    font-size: 40px;
    margin-bottom: 20px;
}

.contentProf{
    font-size: 17px;
    margin-bottom: 40px;
}

.maincontent{
    padding: 0 15%;
    margin-bottom: 70px;
}

.rmbtn{
    margin-bottom: 60px;
    border: 2px solid white;
    width: 120px;
    padding: 10px;
}

.aictecells{
    margin-left: 5%;
    margin-right: 5%;
    border: 5px solid #C4C4C4;
    margin-bottom: 70px;
    background-color: #ffffff;
}

.cellsheading{
    font-size: 50px;
    color: #1C3A5B;
    font-family: 'Playfair Display';
    padding: 3%;
}

.cellsBox{
    width: 33%;
    padding: 3%;
}

.cellsBox{
    padding-top: 0px;
    margin-right: 5%;
}
.cellsheading{
    padding-bottom: 30px;
}

.subcellbox{
    width: 100%;
    border-bottom: 1px solid black;
    margin-right: 3%;
    padding: 20px 0px;
    font-family: 'Playfair Display';
    font-size: 20px;

}

.cellsBoxes{
    display: flex;
} */