/* ---------------------------- PRESENT CODE IS WORKING FINE TO DISPLAY A FOOTER IN A DEVICE HAVING AT LEAST MINIMUM WIDTH 785PX---------------------- */

.lastblock {
  border-right: none;
}

.footer_social_icons{
  display: flex;
  justify-content: center;
}

.footer_logo_facebook_image{
  padding-right: 20px;
}
.footer_logo_twitter_image{
  padding-left: 20px;
}