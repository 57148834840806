.prelease{
    left: 6rem;
    /* top: 80rem; */
    /* overflow: hidden; */
    background: #FFFFFF;
 }
 .prelease p{
    font-family: 'Playfair Display';
    font-weight: 600;
    font-style: normal;
    font-size: 40px;
    margin-top: 1rem;
    margin-left: 1rem;
    color: #0D731D;;
 }
 .datesheet{
     margin-top: 2rem;
     left: 0;
 }
 .cirbox1{
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid black;
 }

 .datebox1{
    height: fit-content;
    width: fit-content;
    /* border: 2px solid black; */
    margin-right: 1rem;
    display: inline;
 }
 /* .prelease button{
     position: absolute;
     color: #0D731D;;
     border: 3px solid #0D731D;;
     height: 2rem;
     width: 7rem;
    bottom: 1rem;
    right: 2rem;
    
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    backdrop-filter: blur(10px);
 } */