.main__head4{
    display: flex;
    /* justify-content: center;
    align-items: center; */
}

.sub__head4{
    width: 100%;
}

.cirbox{
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
    margin-bottom: 40px;
    border-bottom: 2px solid green;
}

.circulars_heading h2{
    text-decoration: underline;
    font-size: 45px;
    font-weight: 600;
    font-family: 'Playfair Display';
    /* margin: 10px; */
    padding: 18px;
    text-align: center;
}

.circulardiv{
    /* border-left: 10%;
    border-right: 10%; */
}

.datebox{
    height: 65px;
    width: 65px;
    border: 2px solid black;
    margin-right: 25px;
    display: inline;
}

.circularheadings{
    padding-top: 20px;
}



.initiative_heading h2{
    text-decoration: underline;
    font-size: 45px;
    font-weight: 600;
    font-family: 'Playfair Display';
    /* margin: 10px; */
    padding: 18px;
    text-align: center;
    margin-bottom: 40px;
}

.flagboxes{
    display: flex;
    width: 100%;
    height: max-content;
    /* border: 10px solid black; */
    /* background-image: url("../../assets/indian_flag_bg.png");
    background-repeat: no-repeat; */
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
}

.flagbox1, .flagbox2{
    width: 50%;
    height: auto;
    background-image: url("../../assets/indian_flag_bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-bottom: 20px;
}

.flagbox1{
    margin-right: 4%;
}
.flagbox2{
    margin-left: 4%;
}

.flagbox_heading{
    padding-top: 5%;
    font-family: 'Playfair Display';
    font-size: 24px;   
    height: 100px;
    font-weight: bold; 
}

.flagbox_para{
    padding: 5%;
}

.learnmore{
    font-weight: bold;
    font-family: roboto;
    margin-right: 20px;
}

.inoneline{
    display: flex;
    justify-content: center;
}


.bottom4boxes{
    width: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    display: flex;
    margin-left: 1.5rem;
}

.bottombox{
    height: auto;
    width: 20%;
    border: 2px solid black;
    padding: 2.5%;
    margin-right: 5%;
}

.bottomboxlast{
    height: auto;
    width: 20%;
    border: 2px solid black;
    padding: 2.5%;
}

.inonelinebottom{
    justify-content: left;
}

.bottomboxheading{
    font-family: 'Playfair Display';
    font-size: 24px;   
    height: 100px;     
    font-weight: bold;
}

.bottomboxpara{
    padding-left: none;
    margin-bottom: 15px;
}

.inonelineimg{
    display: flex;
}

.inonelineimg img{
    width: 35px;
    height: 35px;
    padding-right: 10px;
}

.lastbottombox{
    margin-right: none;
    width: 20.55%;
}

.datebox img{
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 1100px) and (min-width: 0px) {
    
    .bottom4boxes, .flagboxes{
        display: inline;
    }

    .flagbox1, .flagbox2{
        margin-bottom: 20px;
    }

    .bottombox, .flagbox1, .flagbox2{
        width: 100%;
    }

    .flagbox2{
        margin-left: 0;
    }

    .bottomboxheading{
        height: 60px;
    }

    .bottombox{
        margin-bottom: 20px;
    }

}