.aboutus_main_head{
    background-color: #fff;
}

.temp_heading h1{
    text-align: center;
}

.aboutus_image_div img{
    width: 100%;
    height: auto;
}

.aboutus_image_div {
    position: relative;
    text-align: center;
    color: #FFFFFF;
}

.imgtxt{
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);  
    font-size: 70px;
    font-family: 'Noto Sans';
    font-weight: 500;
}

.smallimgtxt{
    display: inline;
}

.smallimgtxt img{
    width: 20px;
    height: 15px;
}

.txtaboutus, .homelogoimg{
    position: absolute;
    top: 58%;
    left: 10%;
    transform: translate(-50%, -50%);  
    /* font-size: 70px; */
    font-family: 'Noto Sans';
    /* font-weight: 500;    */
}

.homelogoimg{
    left: 5.8%;
}

.outernavtype{
    width: 100%;
    padding: 30px 10%;
}

.navtype{
    display: flex;
    align-items: center;
    padding: 15px 40px;
    background-color: #D1E8F2;
    /* padding-left: 15%;
    padding-right: 15%; */
    /* width: 60% */
}

.navtypeboxes{
    width: 25%;
    padding: 20px;
    border-right: 2px solid black;
    text-align: center;
    font-size: 27px;
    font-family: 'Playfair Display';
    font-size: 24px;   
    font-weight: bold;
}

.lastnavtypeboxes{
    border-right: none;
}


.overviewbox, .organisationbox {
    margin-top: 150px;
    margin-left: 8%;
    margin-right: 8%;
    padding: 20px;
}

.overview_heading, .organisation_heading{
    font-size: 60px;
    font-weight: bold;
    font-family: 'Playfair Display';
    color: #07687D;
    width: 100%;
    border-bottom: 4px solid black;
}

.overview_content, .organisation_content{
    margin: 5%;
    text-align: center;
    font-size: 35px;
    font-family: 'Playfair Display';
}

.organisation_content{
    text-align: left;
}

.organisationbox{
    margin-top: 50px;
}

.objective_heading{
    font-size: 80px;
    font-weight: bold;
    font-family: 'Playfair Display';
    color: #07687D;
    text-align: center;
}

.objectivetabs{
    display: flex;
    margin: 0 10%;
    margin-top: 40px;
    padding: 10px;
}

.tabs123{
    width: 33%;
    font-size: 26px;
    padding: 25px;
    font-family: 'Noto Sans';
}

.visioncentre{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 17%;
    margin-top: 35px;
}


.visioncontent{
    margin: 5%;
    text-align: center;
    font-size: 35px;
    font-family: 'Playfair Display';
    padding: 0% 5%;
    font-weight: 600;
}

.missionbox{
    margin: 100px;
    border: 4px solid black;
}

.mission_heading{
    font-size: 80px;
    font-weight: bold;
    font-family: 'Playfair Display';
    color: #07687D;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 35px;
}

.mission_small_headings{
    text-align: center;
    font-size: 35px;
    font-family: 'Playfair Display';
    padding: 0% 5%;
    font-weight: 600;
    padding-bottom: 60px;
}