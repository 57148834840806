/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Google Fonts */
/* Playfair Display & Noto Sans */
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,600;1,700;1,800;1,900&display=swap'); */

/* Hindi Fonts */
/* @import url('https://fonts.googleapis.com/css2?family=Hind:wght@400;500;600&display=swap'); */


/* Colors */
:root{
  /* Root Colors */
  --black: #2c2738;
  --white: #fff;
  --yellow: #ffd500;
  
  /* Overlay Colors */
  --overlay-black: rgba(44,39,56,0.8);

  /* Homepage Colors */
  --home-links: #D8F0F1;
}

/* Scroll Bars */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #a8a8a8 #f1f1f1;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 0px;
}

/* Font Size */
*{
  font-family: 'Noto Sans', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', "sans-serif";
  font-size: 16px;
}

/* Headings Font-Family */
h1, h2, h3, h4, h5, h6{
  font-family: "Playfair Display", 'Didot', "Times New Roman", "Times", "serif";
}

/* Headings Size */

/* H1 */
h1 {
  font-size: 3.5rem;
}

@media only screen and (max-width: 400px) {
  h1 {
      font-size: 2rem;
  }
}

/* H2 */
h2 {
  font-size: 3.0rem;
}

@media only screen and (max-width: 400px) {
  h2 {
      font-size: 1.75rem;
  }
}

/* H3 */
h3 {
  font-size: 2.75rem;
}

@media only screen and (max-width: 400px) {
  h3 {
      font-size: 1.5rem;
  }
}

/* H4 */
h4 {
  font-size: 2rem;
}

@media only screen and (max-width: 400px) {
  h4 {
      font-size: 1.25rem;
  }
}

/* H5 */
h5 { font-size: 1.25rem; }

@media only screen and (max-width: 400px) {
  h5 { font-size: 1.10rem;  }
}

/* H6 */
h6 { font-size: 1.0rem; }

@media only screen and (max-width: 400px) {
  h6 { font-size: 1rem;  }
}

/* Body Text */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Default Buttons */
button{
  background-color: var(--black);
  color: var(--white);
  padding: auto;
  border: black;
}