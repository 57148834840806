.header1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    right:0;
    align-items: center;
    /* justify-content: center; */
}
.aicte__navbar1 {
    display: flex;
   flex-direction: row;
    /* flex-direction: row; */
    align-items: center;
    width: 100%;
    height: 120px;
    background: #FFFFFF;
    
   }
   .aicte__navbar1 img{
    height: 4rem;
    /* margin-top: 1px;
    margin-left:1rem; */
    position: absolute;
    top: 10px;
    left: 1.51%;
   }
   .aicte__navbar1__header{
       display: flex;
       /* flex-direction: row; */
       position: absolute;
       width: 100%;
       height: 40px;
       /* left: 0px;
       right:0px; */
       /* margin-right:0; */
       top: 82px;
       
       background: #2C2738;
       /* border: 1px solid #000000; */
       /* box-sizing: border-box; */
       
       
    }
    .aicte__navbar1__header h3{
        color: #fff;
        font-weight: 400;
        margin-top: -0.2rem;
        left: -104px;
         top: 131px;
         font-family: Noto Sans;
         font-style: normal;
         font-size: 16px;
         line-height: 29px;
         text-align: center;
         width: 100%;
         margin: 5.5px;
    }
   /* .aicte__navbar1__menu{
    position: absolute;
    display: flex;
    flex-direction: row;
    right: 0px;
    width: 100px;
    height: 73px;
    top: 0px;
    left: calc(57% - 175px/2 + 642.5px);
    top: calc(50% - 119px/2 - 23.5px);
    
    background: #2C2738;
    display: flex;
   } */
   
   .menubutton{

    position: absolute;
    top: 0px;
    right: 0px;
    width: 8rem;
    height: 85px;
    /* align-items: center; */
    cursor:pointer;
    color: #fff;
    background: #2C2738;
    font-size: 21px;
    font-weight: 600;
    line-height: 28.6px;
    /* border-radius: 10px; */
    padding: 13px 18px 12px 19px;
    /* border: 4px solid black; */
    /* background: transparent; */

    /* width: 113px;
    height: 15px; */

   }
.menu__bar{
    position: absolute;
width: 145px;
height: 25px;
left: 302px;
top: 203px;

font-family: Noto Sans;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 25px;
}
.MenuBar{
    display: flex;
    flex-direction:initial;
    position: absolute;
    justify-content:space-between;
    width: 100vw;
    height: 0px;
    left: 104px;
    top: 177px;
    
font-family: Playfair Display;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 32px;
/* identical to box height */

/* text-align: center; */

color: #FFFFFF;

}
.menu__bar1,
.menu__bar2,.menu__bar3,.menu__bar4,.menu__bar5 {
    display: flex;
    flex-direction:column;
    position: absolute;
    justify-content:space-between;
}
.menu__bar1 h5{

position: absolute;
width: 84px;
height: 25px;
left: 302px;
top: 228px;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
}
.menu__bar6,.menu__bar7,.menu__bar8,.menu__bar9{
    display: flex;
    flex-direction:column;
    position: absolute;
    justify-content:space-between;
}
.menu__bar6,.menu__bar7,.menu__bar8,.menu__bar9 h5{
    position: absolute;
    width: 84px;
    height: 25px;
    left: 302px;
    top: 228px;
    
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
}

  .searchbar {
   display: flex;
   flex-direction: row;
    width: auto;
    height: 50px;
    /* border: 3px solid black; */
    position: absolute;
    top: 20px;
    right: 180px;
    /* align-items:center;  */
    background: transparent;
   cursor:pointer;
    }
    .searchimg{
       position:absolute;
        right:143px;
        top:33px;
        cursor:pointer;
        height:25px;
       
    }
    .aicte__menu__tog{
        display: flex;
        flex-direction:row;
        justify-content:flex-end;
        position: relative;
        cursor: pointer;
    }
    .aicte__navbar1-menu_toggle{
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction:column;
        text-align: start;
        background:#2C2738;
        padding: 2rem;
        position: absolute;
        top: 40px;
        right: -20px;
        /* left:10px; */
        
        margin-top: -0.7rem;
        min-width: 100%;
        border-radius: 5px;
        box-shadow: 0px 0px 50px rgba(0,0,0,0.2);
  }
  .aicte__navbar1-menu_toggle-links{
    display: flex;
    flex-direction:column;
    position:relative;
    color:#fff ;
    width: 95vw;
    height: 100%;
    top: 12px; 
    /* right:0px; */
    left:0px;
    cursor: default;  
}
.aicte__navbar1-menu svg {
    position:absolute;
    top:0px;
    cursor: pointer;
}
  /* .aicte__navbar1-sign button1
  {
      
      color: #fff;
      font-size: var(--font-family);
      font-weight: 500;
      width: 100px;
      font-size: 18px;
      line-height: 25px;
      background: #ff4820;
      cursor: pointer;
      position: relative;
      width: 91.74px;
      height: 34.23px;
      left: 1334.81px;
      top: 10.96px;
    }
    
  .aicte__navbar1__input{
      width: 120.53px;
      height: 33px;
      top: 10.09px;
      left: 1200.71px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 6rem;
      
  }
  .aicte__navbar1__input img{
    left: 89.95%;
    right: 8.79%;
    top: 1.82%;
    bottom: 95.79%;
    
    }
  .aicte__navbar1__input button{
      background: #000000;
      width: 30px;
      height: 25px;
      cursor: pointer;
     
    }
    .aicte__navbar1__log{
        margin-right: 5rem;
        display: flex;
        
        
    }
    .aicte__navbar1__log button{
        height: 25px;
        width: 66px;
        display: flex;
        padding-right: 2rem;
        filter: drop-shadow(0px 4px 1px rgba(0, 0, 0, 0.25));
        background-color: #000000;
        justify-items: center;
        padding-top: 0.2rem;

    }
    .aicte__navbar1__log h5{
        color: #fff;
        font-size: small;
        padding-left: 0.5rem;
        
    }
    .aicte__menu__tog{
        display: flex;
        flex-direction:row;
        justify-content:flex-end;
        position: relative;
        cursor: pointer;
        
    }
  .aicte__navbar1-menu {
      width: 100vw;
      height: 100vh;
      margin-left: 1rem;
      display: none;
      position: relative;
  }
  .aicte__navbar1-menu_toggle{
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction:column;
        text-align: start;
        background:#2C2738;
        padding: 2rem;
        position: absolute;
        top: 40px;
        right: 0px;
        
        margin-top: -0.7rem;
        min-width: 100%;
        border-radius: 5px;
        box-shadow: 0px 0px 50px rgba(0,0,0,0.2);
  }
  .aicte__navbar1-menu_toggle-links{
      display: flex;
      flex-direction:column;
      color:#fff ;
      width: 1344px;
      height: 400px;
      top: 121px; 
      cursor: default;  
  }
  .menu__bar{
      margin-top: -2rem;
      display: flex;

  }
.menu__bar h5{
      margin-left: 5rem;
  }
.MenuBar{ 
    width: 100px;
    height: 32px;
    left: 104px;
    top: 177px;
    
    font-family: Playfair Display;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    
    color: #FFFFFF;
  }
  
  .aicte__navbar1-menu svg {
      cursor: pointer;
  }
  .aicte__navbar1-menu_container {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-direction:column;
      text-align: start;
      background:#2C2738;
      
      padding: 2rem;
      position: absolute;
      top: 40px;
      right: 0px;
      margin-top: 1rem;
      min-width: 105%;
      border-radius: 5px;
      box-shadow: 0px 0px 50px rgba(0,0,0,0.2);
  }
  
  .aicte__navbar1-menu_container p {
      margin: 1rem 0;
      color: #fff;
  }
  .aicte__navbar1-menu_container button{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: #0E7FFC;
  }
  .aicte__navbar1-menu_container-links-sign {
      display: none;
      cursor: pointer;
  }
  
  @media screen and (max-width: 1050px) {
      .aicte__navbar1-links_container {
          display: none;
          
      }
      .aicte__navbar1-menu {
          display: flex;
      }
  }
  @media screen and (max-width: 700px) {
      .aicte__navbar1 {
          padding: 2rem 4rem;
      }
  }
  
  @media screen and (max-width: 550px) {
      .aicte__navbar1 {
          padding: 0rem;
      }
  
      .aicte__navbar1-sign {
          display: none;
      }
  
      .aicte__navbar1-menu_container {
          top: 20px;
      }
  
      .aicte__navbar1-menu_container-links-sign {
          display: block;
      }
  }

   
    */