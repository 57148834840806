.floating{
    display: flex;
       /* flex-direction: row; */
       position: relative;
       width: 100%;
       height: 40px;
       /* left: 0px;
       right:0px; */
       /* margin-right:0; */
       /* top: 0px; */
       /* margin-bottom:; */
       background: #2C2738;
       margin: none;
}
.floating h3{
    color: #fff;
    font-weight: 400;
    /* margin-top: -0.2rem; */
    left: -104px;
     top: 131px;
     font-family: Noto Sans;
     font-style: normal;
     font-size: 16px;
     line-height: 29px;
     text-align: center;
     width: 100%;
     margin: 5.5px;
}
.aicte__navbar1-menu {
    margin-left: 1rem;
    display: none;

    /* position: relative; */
}
@media screen and (max-width: 1050px){
    .aicte__navbar1-menu {
        display: flex;}
}

/* .floating{
    padding: 30px;
    margin: none;
    width: 100%;
} */