.main__head2{
    display: flex;
    height: max-content;
    width: 100%;
}

.sub__head2{
    width: 25%;
    padding: 40px;
    background: rgba(249, 236, 169, 0.35);
}
.sub__head1{
    width: 75%;
}
.slidebar__list1, .slidebar__list2{
    padding: 25px 0px;
}
.head2__slidebar h4, .head2__slidebar h3, .impodiv h2, .eventdiv h2{
    text-decoration: underline;
    font-family: 'Playfair Display';
}



/* -------------------------------------- */

.impodiv, .eventdiv{
    padding: 30px 0 30px 30px;
    text-align: center;
}

.impodiv img{
    width: 100%;
    height: 40%;
}

.eventdiv iframe{
    width: 100%;
    height: 60%;
}

.impodiv h2, .eventdiv h2{
    padding-bottom: 20px;
}